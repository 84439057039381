import '../shared/nav/collapsing_md_nav'
import '../shared/phone'
import './nav'
import '../shared/buyers_page_personalizer'


import PhoneNumber from '../shared/phone.js'
import AddToCartForms from '../shared/add_to_cart_forms'
import Checkout from '../shared/checkout'
import DepositRedemptions from '../shared/deposit_redemptions'
import CartNav from '../shared/nav/nav_cart'
import AddToCart from '../full-gpe/cart/add-to-cart'
import VueLoader from '../default/vue-loader'
import { store } from'../default/store'
import HotelIndex from '../shared/add_to_cart_forms/h4h_hotels/hotel_index'



document.addEventListener('turbo:load', () => {
  AddToCartForms.loadBottomForm()
  CartNav.addCartToNavbar()
  DepositRedemptions.findCustomerDeposits()
  Checkout.loadWithAddOn()
  AddToCart.AddToCart()
  VueLoader.loadVueComponent(HotelIndex, document.getElementById('hotelIndex'), store)

  let phoneNumber = PhoneNumber()
  $('.navbar-right').append(`<li class="menu-item phone-contact"><a href="tel:${phoneNumber}"><div class="original">Questions? Call Us</div></a></li>`)
})
