const showHoverLine = elem => {
  let original_elem = elem.querySelector('.original')
  $(original_elem).addClass('hoverline')
}

const hideHoverLine = elem => {
  let original_elem = elem.querySelector('.original')
  $(original_elem).removeClass('hoverline')
}


document.addEventListener('turbo:load', () => {
  if(document.querySelectorAll('.navbar-nav > li').length > 0) {
    document.querySelectorAll('.navbar-nav > li').forEach((elem) => {
      elem.addEventListener('mouseenter', () => {
        showHoverLine(elem)
      })
  
      elem.addEventListener('mouseleave', () => {
        hideHoverLine(elem)
      })
    })
  }


  // document.querySelectorAll('.navbar-nav > .dropdown, .navbar-default .phone-dropdown, .navbar-default .account-dropdown').forEach( (elem) => {
  //   elem.addEventListener('mouseenter', (event) => {
  //     $(event.currentTarget).addClass('open')
  //   })

  //   elem.addEventListener('mouseleave', (event) => {
  //     $(event.currentTarget).removeClass('open')
  //   })
  // })
})
